.projects
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    grid-gap: 20px
    figure
        position: relative
        img
            width: 100%
            min-height: calc(100vh - 114.8px)
            max-height: calc(100vh - 114.8px)
            display: block
            object-fit: cover
        .overlay
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            background-color: black
            opacity: 0.5
        figcaption
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            text-decoration: underline
            z-index: 998
            width: 100%
            text-align: center
            pointer-events: none
        &:hover
            .overlay
                opacity: 0
            figcaption
                transform: translate(-50%, -50%) scale(8)
    @include tablet
        grid-template-rows: 1fr 1fr
        grid-template-columns: 1fr
        > div
            grid-column: 3/1
        figure
            &:hover
                .overlay
                    opacity: 0.5
                figcaption
                    transform: translate(-50%, -50%) scale(1.5)
            img
                min-height: calc(55vh - 109.8px)
                max-height: calc(55vh - 109.8px)