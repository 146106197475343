nav
    display: flex
    justify-content: space-between
    align-content: center
    padding: 20px
    border-bottom: $color-side 3px solid
    position: sticky
    top: 0
    background-color: $color-main
    transition: background-color 0.7s ease-in-out
    z-index: 999
    svg
        cursor: pointer
        rect, path
            fill: $color-side
            transition: 0.7s ease-in-out
    a
        display: inherit
    &.open
        background-color: $color-side
        svg
            rect, path
                fill: $color-main
        .nav-trigger
            rect
                height: 20px
body[data-theme="red_theme"]
    nav
        background-color: $color-blue
        z-index: 9999999
        position: fixed
        margin-top: 20px
        width: calc(100% - 40px)
        max-width: 800px
        min-width: 300px
        left: 50%
        transform: translateX(-50%)
        border-radius: 10px
        box-shadow: 0 5px 30px rgba(0,0,0,.5)
        border-bottom: 0
        // z-index: 9999
        svg
            rect, path
                fill: $color-red
        // &.open
        //     background-color: $color-red
        //     svg
        //         rect, path
        //             fill: $color-blue
        //     .nav-trigger
        //         rect
        //             height: 20px
    .index
        padding: 0
        height: 100vh
        .projects
            grid-template-columns: 1fr 1fr
            grid-gap: 0
            height: 100vh
            @include tablet
                grid-template-columns: 1fr
                figure
                    background-position: top !important
            > div
                display: none
            figure
                overflow: hidden
                background-size: cover
                .overlay
                    z-index: 9
                img
                    display: none
                &:first-of-type
                    background-image: url(../img/things/IMG_9809.jpg)
                    background-position: right
                &:last-of-type
                    background-image: url(../img/things/IMG_9948.JPG)
                    background-position: left
    side
        > div
            left: 50%
            max-width: 800px
            transform: translateX(-50%)
            width: calc( 100vw - 40px )
side
    display: block
    div
        z-index: 999
        display: flex
        flex-direction: column
        text-align: right
        position: fixed
        height: calc(100vh - 74.77px)
        width: 100vw
        background-color: $color-side
        padding: 20px
        justify-content: flex-end
        top: 100vh
        transition: top 0.5s ease-in-out
        padding-bottom: 100px
        a
            color: $color-main
            font-size: 55px
    &.open
        div
            top: 74.77px


footer
    background-color: $color-side
    text-align: right
    padding: 20px
    color: $color-main
    font-size: 17px
    a
        color: $color-main
        font-size: 17px


.login-page
    nav
        background-color: $color-pink
        border-bottom: $color-yellow 3px solid
        svg
            rect, path
                fill: $color-yellow
        &.open
            background-color: $color-yellow
            svg
                rect, path
                    fill: $color-pink
            .nav-trigger
                rect
                    height: 20px
    side
        div
            background-color: $color-yellow
            a
                color: $color-pink
    footer
        background-color: $color-yellow
        color: $color-pink
        a
            color: $color-pink
    main
        display: flex
        justify-content: center
        align-items: center
        // background-color: $color-pink
        .login-form
            input
                border-radius: 0
                padding: 15px 20px 20px 20px
                margin: 30px
                width: 300px
                transition: 0.7s ease-in-out
                color: $color-yellow
                font-size: 28px
                line-height: 1.4
                font-family: sofia-pro,sans-serif
                font-weight: 700
                font-style: normal
                text-transform: uppercase
                text-align: center
                &::placeholder
                    transition: 0.7s ease-in-out
                    font-size: 28px
                    line-height: 1.4
                    font-family: sofia-pro,sans-serif
                    font-weight: 700
                    font-style: normal
                    text-transform: uppercase
                &:focus
                    outline: none !important
        .error-msg
            font-size: 28px
            line-height: 1.4
            font-family: sofia-pro,sans-serif
            font-weight: 700
            font-style: normal
            text-transform: uppercase
            text-align: center