.grid
    display: grid
    grid-template-columns: repeat(6, 1fr)
    grid-gap: 20px
    .grid-item
        img
            width: 100%
            height: auto
            display: block
        &:nth-of-type(6n + 1)
            grid-column: 1 / 7
        &:nth-of-type(6n + 2)
            grid-column: 1 / 4
        &:nth-of-type(6n + 3)
            grid-column: 4 / 7
        &:nth-of-type(6n + 4)
            grid-column: 1 / 3
        &:nth-of-type(6n + 5)
            grid-column: 3 / 5
        &:nth-of-type(6n + 6)
            grid-column: 5 / 7
    @media ( min-width: 1000px )
        .grid-item
            grid-template-columns: repeat(4, 1fr)
            &:nth-of-type(6n + 1)
                grid-column: 2/5
            &:nth-of-type(6n + 2)
                grid-column: 1/3
            &:nth-of-type(6n + 3)
                grid-column: 6/3
            &:nth-of-type(6n + 4)
                grid-column: 4/7
            &:nth-of-type(6n + 5)
                grid-column: 1/5
            &:nth-of-type(6n + 6)
                grid-column: 5/7