#cookiePopup 
    background-color: $color-pink
    position: absolute
    padding: 2em 1.4em
    transition: all 0.5s ease-in
    height: calc(100vh - 71.8px)
    width: 100%
    text-align: center
    display: flex
    justify-content: center
    flex-direction: column
    z-index: 99999
    div
        display: flex
        align-items: center
        justify-content: center
        p
            margin-bottom: 40px
        button 
            background-color: $color-yellow
            color: $color-pink
            border: 3px solid $color-yellow
            font-size: 28px
            line-height: 1.4
            font-family: sofia-pro,sans-serif
            font-weight: 700
            font-style: normal
            text-transform: uppercase
            outline: none
            padding: 6px 20px 10px
            margin-right: 20px
            cursor: pointer
            transition: all 0.2s ease-in
            &:last-of-type
                margin-right: 0
                background-color: $color-pink
                color: $color-yellow
                border: 3px solid $color-yellow
            &:hover
                transform: rotate(4deg)
    &.hide 
        visibility: hidden
    &.show 
        visibility: visible

body[data-theme="pink_theme"]
    #cookiePopup 
        background-color: $color-pink
        div
            button 
                background-color: $color-yellow
                color: $color-pink
                border: 3px solid $color-yellow
                &:last-of-type
                    background-color: $color-pink
                    color: $color-yellow
                    border: 3px solid $color-yellow

body[data-theme="blue_theme"]
    #cookiePopup 
        background-color: $color-main
        div
            button 
                background-color: $color-side
                color: $color-main
                border: 3px solid $color-side
                &:last-of-type
                    background-color: $color-main
                    color: $color-side
                    border: 3px solid $color-side

body[data-theme="red_theme"]
    
    #cookiePopup 
        background-color: $color-red
        height: 100vh
        div
            button 
                background-color: $color-blue
                color: $color-red
                border: 3px solid $color-blue
                &:last-of-type
                    background-color: $color-red
                    color: $color-blue
                    border: 3px solid $color-blue