*
  margin: 0
  padding: 0
  box-sizing: border-box

html, body
  font-size: 28px
  line-height: 1.4
  font-family: sofia-pro,sans-serif
  font-weight: 700
  font-style: normal
  text-transform: uppercase
  color: $color-side

body
  main
    padding: 20px 20px 20px 0
    min-height: calc( 100vh - 138.8px )
    overflow: hidden
  &.red_theme
    main
      padding: 115px 20px 20px 0
      min-height: 100vh


a
  text-decoration: underline

.clearfix::after
  content: "."
  display: block
  clear: both
  font-size: 0
  height: 0
  visibility: hidden