// SCREEN Sizes
$small: 540px
$mobile: 640px
$tablet: 768px
$desktop: 1000px
$desktoplarge: 1200px
$maxwidth: 1500px

$color-main:    #002BB9
$color-side:    #B1FF00
$color-pink:    #FF0093
$color-yellow:  #FFFF00
$color-red:     #62102F
$color-blue:    #B4D0E6
$color-rosa:    #F1A5ED

// Blue Theme
body[data-theme="blue_theme"]
    background-color: $color-main
    color: $color-side
    a
        color: $color-side
    nav
        border-bottom: $color-side 3px solid
        background-color: $color-main
        svg
            rect, path
                fill: $color-side
        &.open
            background-color: $color-side
            svg
                rect, path
                    fill: $color-main
    side
        div
            background-color: $color-side
            a
                color: $color-main
    footer
        background-color: $color-side
        color: $color-main
        a
            color: $color-main
    // Lightbox
    .sl-wrapper
        .sl-close
            color: $color-main
        .sl-counter
            color: $color-main
        .sl-image
            .sl-caption
                color: $color-main
    .sl-overlay
        background: $color-side

    // Login Form
    .login-form
        input
            border: 3px solid $color-side
            background-color: $color-main
            color: $color-side
            &::placeholder
                color: $color-side
            &:focus
                color: $color-main
                background-color: $color-side
                &::placeholder
                    color: $color-main
    .error-msg
        color: $color-side


// Pink Theme
body[data-theme="pink_theme"]
    background-color: $color-pink
    color: $color-yellow
    a
        color: $color-yellow
    nav
        background-color: $color-pink
        border-bottom: $color-yellow 3px solid
        svg
            rect, path
                fill: $color-yellow
        &.open
            background-color: $color-yellow
            svg
                rect, path
                    fill: $color-pink
    side
        div
            background-color: $color-yellow
            a
                color: $color-pink
    footer
        background-color: $color-yellow
        color: $color-pink
        a
            color: $color-pink
    // Lightbox
    .sl-wrapper
        .sl-close
            color: $color-pink
        .sl-counter
            color: $color-pink
        .sl-image
            .sl-caption
                color: $color-pink
    .sl-overlay
        background: $color-yellow

    // Login Form
    .login-form
        input
            border: 3px solid $color-yellow
            background-color: $color-pink
            color: $color-yellow
            &::placeholder
                color: $color-yellow
            &:focus
                color: $color-pink
                background-color: $color-yellow
                &::placeholder
                    color: $color-pink
    .error-msg
        color: $color-yellow

body[data-theme="red_theme"]
    background-color: $color-red
    color: $color-blue
    a
        color: $color-blue
    nav
        background-color: $color-red
        border-bottom: $color-blue 3px solid
        svg
            rect, path
                fill: $color-blue
        &.open
            background-color: $color-blue
            svg
                rect, path
                    fill: $color-red
    side
        div
            background-color: $color-blue
            a
                color: $color-red
    footer
        background-color: $color-blue
        color: $color-red
        a
            color: $color-red
    // Lightbox
    .sl-wrapper
        .sl-close
            color: $color-blue
        .sl-counter
            color: $color-blue
        .sl-image
            .sl-caption
                color: $color-blue
    .sl-overlay
        background: $color-red

    // Login Form
    .login-form
        input
            border: 3px solid $color-blue
            background-color: $color-red
            color: $color-blue
            &::placeholder
                color: $color-blue
            &:focus
                color: $color-red
                background-color: $color-blue
                &::placeholder
                    color: $color-red
    .error-msg
        color: $color-blue